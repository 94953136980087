<template>
  <Page class="content" v-loading="loading">
    <div v-if="initLoad">
      <div v-if="list.length > 0">
        <div class="sy">
          <div class="pro_list_search">
            <div class="none"></div>
            <div class="pro_search_item">
              <div class="pro_search_header">{{ $t('Related Categories') }}</div>
              <div class="pro_search_body">
                <a>Headset</a>
                <a>Mobile Phone</a>
              </div>
            </div>
            <div class="pro_search_item">
              <div class="pro_search_header">{{ $t('Service') }}</div>
              <div class="pro_search_body">
                <div class="checkbox_item">
                  <div class="checkbox"><img src="~img/login_dui.svg" /></div>
                  <span>{{ $t('Cash On Delivery') }}</span>
                </div>
                <div class="checkbox_item">
                  <div class="checkbox"><img src="~img/login_dui.svg" /></div>
                  <span>{{ $t('Free Shipping') }}</span>
                </div>
              </div>
            </div>
            <div class="pro_search_item">
              <div class="pro_search_header">{{ $t('Score') }}</div>
              <div class="pro_search_body">
                <div class="checkbox_item xuan">
                  <div class="checkbox"><img src="~img/login_dui.svg" /></div>
                  <div class="pro_pingjia_star">
                    <img src="~img/star_01.png" />
                    <img src="~img/star_01.png" />
                    <img src="~img/star_01.png" />
                    <img src="~img/star_01.png" />
                    <img src="~img/star_01.png" />
                  </div>
                </div>
                <div class="checkbox_item">
                  <div class="checkbox"><img src="~img/login_dui.svg" /></div>
                  <div class="pro_pingjia_star">
                    <img src="~img/star_01.png" />
                    <img src="~img/star_01.png" />
                    <img src="~img/star_01.png" />
                    <img src="~img/star_01.png" />
                    <img src="~img/star_03.png" />
                  </div>
                </div>
                <div class="checkbox_item">
                  <div class="checkbox"><img src="~img/login_dui.svg" /></div>
                  <div class="pro_pingjia_star">
                    <img src="~img/star_01.png" />
                    <img src="~img/star_01.png" />
                    <img src="~img/star_01.png" />
                    <img src="~img/star_01.png" />
                    <img src="~img/star_02.png" />
                  </div>
                </div>
                <div class="checkbox_item">
                  <div class="checkbox"><img src="~img/login_dui.svg" /></div>
                  <div class="pro_pingjia_star">
                    <img src="~img/star_01.png" />
                    <img src="~img/star_01.png" />
                    <img src="~img/star_01.png" />
                    <img src="~img/star_03.png" />
                    <img src="~img/star_02.png" />
                  </div>
                </div>
                <div class="checkbox_item">
                  <div class="checkbox"><img src="~img/login_dui.svg" /></div>
                  <div class="pro_pingjia_star">
                    <img src="~img/star_01.png" />
                    <img src="~img/star_01.png" />
                    <img src="~img/star_01.png" />
                    <img src="~img/star_02.png" />
                    <img src="~img/star_02.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <JLImg src="7eff419a.png"></JLImg>
          <div class="pro_list flex_box">
            <div class="pro_list_shaixuan">
              <!-- <div class="shaixuan_daohang"></div> -->
                <!-- <div class="shaixuan_card">
                  <span class="sort_bya_title">{{ $t('Sort By') }}:</span>
                  <div class="sort_bya"> -->
                    <!-- <div class="sort_bya_item" @click="sortlei('Best Match')" :class="{ xuan: sort == 'Best Match' }">{{ $t('Best Match') }}</div> -->
                    <!-- <div class="sort_bya_item" @click="sortlei('Orders')" :class="{ xuan: sort == 'Orders' }">{{ $t('order.Orders') }}</div>
                    <div class="sort_bya_item" @click="sortlei('Newest')" :class="{ xuan: sort == 'Newest' }">{{ $t('Newest') }}</div>
                    <div class="sort_bya_item" @click="sortlei('Price')" :class="{ xuan: sort == 'Price' }">
                      <span>{{ $t('Price') }}</span>
                      <div class="icon" v-if="sort == 'Price'">
                        <div v-if="!priceRank">
                          <img src="@/assets/imgs/price_jt_02.svg" />
                          <img class="down" src="~img/price_jt_01.svg" />
                        </div>
                        <div v-else>
                          <img src="@/assets/imgs/price_jt_01.svg" />
                          <img class="down" src="@/assets/imgs/price_jt_02.svg" />
                        </div>
                      </div>
                      <div class="icon" v-else>
                        <img src="@/assets/imgs/price_jt_01.svg" />
                        <img class="down" src="@/assets/imgs/price_jt_01.svg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="shaixuan_line"></div> -->
                <div class="shaixuan_card">
                  <span class="sort_bya_title">{{ $t('Category') }}</span>
                  <el-checkbox-group v-model="filters.category">
                    <el-checkbox value="Refrigerator" label="Refrigerator" size="small" />
                    <el-checkbox value="Television" label="Television" size="small" />
                    <el-checkbox value="Air Conditioner" label="Air Conditioner" size="small" />
                    <el-checkbox value="Washing Machine" label="Washing Machine" size="small" />
                    <el-checkbox value="Microwave Oven" label="Microwave Oven" size="small" />
                    <el-checkbox value="Deep Freezer" label="Deep Freezer" size="small" />
                  </el-checkbox-group>
                </div>

                <div class="shaixuan_card" v-if="filters.category.length < 2">
                  <span class="sort_bya_title">{{ $t('Type') }}</span>
                  <el-radio-group v-model="filters.type">
                    <el-radio value="Invertor" label="Invertor" size="small" />
                    <el-radio value="Non-Inverter" label="Non-Inverter" size="small" />
                    <el-radio value="Side By Side Refrigerator" label="Side By Side Refrigerator" size="small" />
                  </el-radio-group>
                </div>
                
                <div class="shaixuan_card">
                  <span class="sort_bya_title">{{ $t('Price Range') }}</span>
                  <div class="shaixuan_card_price flex_box">
                    <input :placeholder="$t('Min Price')" v-model="greaterThan" @keyup="changeNum('greaterThan')" />
                    <!-- <span>-</span> -->
                    <input :placeholder="$t('Max Price')" v-model="lessThan" @keyup="changeNum('lessThan')" />
                    <button @click="addLabel({ page: 1, text: this.params.keyword, sx: 1 })">{{ $t('OK') }}</button>
                  </div>
                  <el-radio-group v-model="filters.price">
                    <el-radio label="All Price" value="All Price" size="small" />
                    <el-radio label="Under Rs.80,000" value="Under Rs.80,000" size="small" />
                    <el-radio label="Rs.100,000 to Rs.150,000" value="Rs.100,000 to Rs.150,000" size="small" />
                    <el-radio label="Rs.150,000 to Rs.200,000" value="Rs.150,000 to Rs.200,000" size="small" />
                    <el-radio label="Rs.250,000 to Rs.300,000" value="Rs.250,000 to Rs.300,000" size="small" />
                    <el-radio label="Rs.350,000 to Rs.400,000" value="Rs.350,000 to Rs.400,000" size="small" />
                    <el-radio label="Rs.450,000 to Rs.500,000" value="Rs.450,000 to Rs.500,000" size="small" />
                  </el-radio-group>
                </div>

                <div class="shaixuan_card" v-if="filters.category.length < 2">
                  <span class="sort_bya_title">{{ $t('Product Features') }}</span>
                  <el-checkbox-group v-model="filters.productFeatures">
                    <el-checkbox value="Ice Maker" label="Ice Maker" size="small" />
                    <el-checkbox value="In Door Water Dispenser" label="In Door Water Dispenser" size="small" />
                    <el-checkbox value="Finger Print Resistance" label="Finger Print Resistance" size="small" />
                    <el-checkbox value="LED Lightning" label="LED Lightning" size="small" />
                    <el-checkbox value="Water Filter" label="Water Filter" size="small" />
                  </el-checkbox-group>
                </div>

                <div class="shaixuan_card">
                  <span class="sort_bya_title">{{ $t('Popular Brands') }}</span>
                  <el-checkbox-group v-model="filters.popularBrands">
                    <el-checkbox value="Candy" label="Candy" size="small" />
                    <el-checkbox value="Haier" label="Haier" size="small" />
                  </el-checkbox-group>
                </div>

                <div class="shaixuan_card lastItem">
                  <span class="sort_bya_title">{{ $t('Colors') }}</span>
                  <div class="flex_box colors">
                    <div 
                      v-for="(color, index) in colorList"
                      :key="index"
                      class="singleColor"
                      :class="{ selected: color.selected }"
                      @click="selectColor(index)"
                    >
                      <span :style="{ backgroundColor: color.code }"></span>
                    </div>
                  </div>
                </div>

                <div class="shaixuan_card lastItem">
                  <span class="sort_bya_title">{{ $t('Current Deals') }}</span>
                  <el-checkbox-group v-model="filters.currentDeals">
                    <el-checkbox value="On Sale" label="On Sale" size="small" />
                    <el-checkbox value="Clearance" label="Clearance" size="small" />
                  </el-checkbox-group>
                </div>
              <!-- <div class="w12 shaixuan_card mt15" v-if="priceLable">
                <span class="sort_bya_title">{{ $t('Filtered By') }}:</span>
                <div class="filtered_item">
                  <span v-if="greaterLabel && lessLable">Price:{{ greaterLabel }}-{{ lessLable }}</span>
                  <span v-else-if="greaterLabel">Price:{{ greaterLabel }} <span class="iconfont icon-jiantou_xiangshang float_right"></span></span>
                  <span v-else-if="lessLable">Price:{{ lessLable }}<span class="iconfont icon-jiantou_xiangxia float_right"></span></span>
                  <i @click="close">×</i>
                </div>
                <a @click="close">{{ $t('Clear All') }}</a>
              </div> -->
            </div>
            <div class="pro_list_body">
              <div class="flex_center" style="margin-bottom: 24px;">
                <div class="left-section">
                  <div class="breadcrumbs">
                    <span class="prev">Homepage</span> / <span class="prev">Shop</span> / <span class="current">Refrigerators</span>
                  </div>
                </div>
                <div class="sortBy flex_center">
                  <span>{{ $t('Sort by') }}:</span>
                  <ElSelect v-model="sort" @change="sortlei(sort)" class="option-box">
                    <el-option :key="index" v-for="(sortItem, index) in sortOptions" :value="sortItem.value" :label="$t(sortItem.name)"> </el-option>
                  </ElSelect>
                </div>
              </div>
              <div v-if="productCount" class="resultsFound"><span>{{ productCount }}</span> {{ $t('Results found') }}.</div>
              <div class="activeFilters flex_box" v-if="!loginFlag && (greaterLabel || lessLable || filters.category.length || filters.type || filters.price || filters.productFeatures.length || filters.popularBrands.length || filters.currentDeals.length || colorList.some(color => color.selected))">
                <div class="filtered_item flex_center">
                  <el-tag v-if="greaterLabel && lessLable" size="large" closable @close="close">Price: {{ greaterLabel }}-{{ lessLable }}</el-tag>
                  <el-tag v-else-if="greaterLabel" size="large" closable @close="close">Price: {{ greaterLabel }}-max</el-tag>
                  <el-tag v-else-if="lessLable" size="large" closable @close="close">Price: 1-{{ lessLable }}</el-tag>
                  <el-tag v-for="(category, index) in filters.category" size="large" :key="index" closable @close="filters.category.splice(index, 1)">{{ category }}</el-tag>
                  <el-tag v-if="filters.type" size="large" closable @close="filters.type = ''">{{ filters.type }}</el-tag>
                  <el-tag v-if="filters.price" size="large" closable @close="filters.price = ''">{{ filters.price }}</el-tag>
                  <el-tag v-for="(productFeature, index) in filters.productFeatures" size="large" :key="index" closable @close="filters.productFeatures.splice(index, 1)">{{ productFeature }}</el-tag>
                  <el-tag v-for="(popularBrand, index) in filters.popularBrands" size="large" :key="index" closable @close="filters.popularBrands.splice(index, 1)">{{ popularBrand }}</el-tag>
                  <template v-for="(color, index) in colorList">
                    <el-tag v-if="color.selected" size="large" :key="index" closable @close="deselectColor(index)">{{ color.name }}</el-tag>
                  </template>
                  <el-tag v-for="(currentDeal, index) in filters.currentDeals" size="large" :key="index" closable @close="filters.currentDeals.splice(index, 1)">{{ currentDeal }}</el-tag>
                  <a @click="clearAll" class="clearAll">{{ $t('Clear All') }}</a>
                </div>
              </div>
              <div class="pro_list_loading" v-if="loginFlag">{{ $t('正在加载中，请稍后~') }}</div>
              <div class="list-box">
                <div class="pro_list_item" v-for="(item, index) of list" :key="index" @click="toDetails(item.id)">
                  <div class="pro_img">
                    <JLImg class="pro_zimg" :src="item.coverImg" />
                    <!-- <div class="guanzhu" @click.stop="guanzhu(item)">
                      <img class="no1" v-if="item.isCollect" src="~img/pro_guanzhu_02.svg" />
                      <img class="no2" v-else src="~img/pro_guanzhu_01.svg" />
                    </div> -->
                    <!-- <div class="sold-out" v-if="item.allStock === 0">
                      {{$t('common.productStatus.soldOut')}}
                    </div> -->
                  </div>
                  <div class="pro_info" style="display: flex; flex-direction: column; justify-content: space-between">
                    <div>
                      <!-- <div class="pro_sx" v-if="item.showImgs">
                        <JLImg
                          v-for="(imgItem, index) of getImgsList(item.showImgs)"
                          :key="index"
                          class="pro_sx_item"
                          :class="item.page == index ? 'xuan' : ''"
                          @click.stop="switchImg({ product: item, selImg: imgItem, index: index })"
                          :src="imgItem.img"
                        />
                      </div> -->
                        <!-- <div class="pro_sx_item xuan"><img src="~img/pro_01.png" /></div> -->
                      
                      <div class="pro_pic_Y" v-if="item.percentage">{{ item.percentage }}</div>
                      <div class="pro_name text_hide2">
                        <!-- <em class="pro_biaoqian new_pro"> <img v-if="item.NEW && item.NEW === 1" src="~img/new.svg" />{{ $t('NEW') }} </em>
                        <em class="ml5 mr5 pro_biaoqian hot_pro" v-if="item.HOT && item.HOT === 1"> <img src="~img/hot.svg" />{{ $t('HOT') }} </em> -->
                        {{ item.name }}
                      </div>
                      <!-- <div class="w12 mb5 text_hide2" style="color: #999; min-height: 30px;">{{ item.buyingPointInfo }}</div> -->
                      <!--<div class="w12" style="color: orange;">{{item.firstCategory.name}}</div>-->
                    </div>
                    <div>
                      <div class="pro_pic">{{ JLTools.forPrice(item.amount) }}</div>
                      <div class="pro_pic_x" v-if="item.lineAmount && item.lineAmount > item.amount && item.lineAmount >= 1">{{ JLTools.forPrice(item.lineAmount) }}</div>
                      <div class="pro_pic_w" v-else>&nbsp;</div>
                      <div class="pro_maidian" v-if="item.commentCount > 0">
                        <Rating :curRating="item.avgLevel" />
                        <div class="score-num">({{ item.commentCount }})</div>
                      </div>
                      <div class="pro_yunfei" v-if="item.freight">
                        <span>freight:+ Rs.{{ item.freight }}</span>
                        <span v-if="item.Installment_BJ && item.Installment_BJ == 1">Installment</span>
                      </div>
                      <div class="xiaoliang rowBC" v-if="0">
                        <div class="pro_pingjia">
                          <span>{{ item.score }}</span>
                        </div>
                        <div>{{ item.saleCount }} {{ $t('people buy') }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fenye">
                <!-- <div class="fenye_left">
                  {{ $t('About') }} <b>{{ productCount }}</b> {{ $t('results were found') }}
                </div> -->
                <div class="fenye_right">
                  <Pagination :config="{ total: productCount, pageSize: pageSize, currentPage: page }" v-if="productCount" @currentPage="handCurrenPage"></Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="content_none">
        <div class="pro_list_none rowCC">
          <img src="~img/pro_list_none.svg" />
          <div class="txt">
            <p>
              {{ $t('We are sorry, We cannot find any product for your searched category') }}
            </p>
          </div>
        </div>
        <PickProduct />
      </div>
    </div>
  </Page>
</template>

<script>
  import Page from '@/components/Page'
  import Pagination from '@/components/Pagination'
  import PickProduct from '../index/PickProduct'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import useStore from '@/store/pinia'
  import Rating from '@/views/order/compontent/rating'

  const { userStore, HsystemStore } = useStore()

  export default {
    name: 'productList',
    components: { Page, Pagination, PickProduct, Rating },
    data() {
      return {
        loading: false,
        initLoad: false,
        list: [],
        productCount: 0,
        query_data: {},
        params: {
          text: ''
        },
        keyword: '',
        sort: 'Best Match', //排序，默认Best Match
        sortItem: '',
        priceRank: false,
        productType: 'GENERAL',
        greaterThan: '', //金额大于
        lessThan: '', //金额小于
        greaterLabel: '', //标签小金额
        lessLable: '', //标签大金额
        priceLable: false, //价格标签
        loadding: 'false',
        page: 1,
        pageSize: 20,
        loginFlag: false,
        collectProductList: [], //收藏商品
        collectLoadFlag: false,
        sortOptions: [
          {
            name:'BestMatch',
            value: 'Best Match'
          },
          {
            name: 'order.MostPopular',
            value: 'Orders'
          },
          {
            name: 'Newest',
            value: 'Newest'
          },
          {
            name: 'order.PriceLowToHigh',
            value: 'PriceLowToHigh'
          },
          {
            name: 'order.PriceHighToLow',
            value: 'PriceHighToLow'
          }
        ],
        filters: {
          type: '',
          price: '',
          category: [],
          productFeatures: [],
          popularBrands: [],
          currentDeals: []
        },
        colorList: [
          { name: 'Grey', code: '#B1B5B8', selected: false },
          { name: 'Red', code: '#A40000', selected: false },
          { name: 'Blue', code: 'var(--Zcolor)', selected: false },
          { name: 'Golden', code: '#BF8E34', selected: false }
        ]
      }
    },
    created() {
      this.params = this.$route.query
      console.log(this.params, 'this.params')
      console.log('跳转参数',this.params);
      this.getList({ page: 1, text: this.params.keyword })
      //初始化分类
      if (!this.JLTools.isNull(this.params.categoryList)) {
        // console.log(this.params.categoryList)
        let categoryList = this.initCategory()
        // console.log('分类数据',categoryList)
        HsystemStore.setBreadcrumbList(categoryList)
      } else {
        HsystemStore.setBreadcrumbList([])
      }
    },
    watch: {
      $route: {
        handler: function (val, oldVal) {
          console.log(val);
          this.params = val.query
          this.getList({ page: 1, text: this.params.keyword })
          //初始化分类
          if (!this.JLTools.isNull(this.params.categoryList)) {
            // console.log(this.params.categoryList)
            let categoryList = this.initCategory()
            // console.log('分类数据',categoryList)
            HsystemStore.setBreadcrumbList(categoryList)
          } else {
            HsystemStore.setBreadcrumbList([])
          }
        },
        // 深度观察监听
        deep: true
      }
    },
    mounted() {},
    methods: {
      //分类
      initCategory() {
        let categoryList = [
          {
            name: 'All Categories',
            data: {
              params: {
                categoryList: JSON.stringify([])
              }
            }
          }
        ]
        if (!this.JLTools.isNull(this.params.categoryList)) {
          let firstCategory = {}
          JSON.parse(this.params.categoryList).forEach((res, i) => {
            let category = res
            let item
            if (i == 0) {
              firstCategory = { name: category.name, id: category.id }
              localStorage.setItem('pageName', 'productList')
              item = {
                name: category.name,
                id: category.id,
                data: {
                  name: 'blankPage',
                  params: {
                    pageName: 'productList',
                    firstCategoryId: category.id,
                    categoryList: JSON.stringify([firstCategory])
                  }
                }
              }
            } else {
              localStorage.setItem('pageName', 'productList')
              item = {
                name: category.name,
                id: category.id,
                data: {
                  name: 'blankPage',
                  params: {
                    pageName: 'productList',
                    firstCategoryId: category.id,
                    categoryList: JSON.stringify([firstCategory, { name: category.name, id: category.id }])
                  }
                }
              }
            }
            categoryList.push(item)
          })
        }
        return categoryList
      },
      selectColor(index) {
        this.colorList.forEach((color, i) => {
          color.selected = (i === index);
        });
      },
      deselectColor(index) {
        this.colorList[index].selected = false;
      },
      //查询商品
      getList(config) {
        if (config.sx && config.sx === 1) {
          this.loginFlag = true
        } else {
          this.loading = true
        }

        this.JLTools.toTop()
        if (!this.JLTools.isNull(config.page)) {
          this.page = config.page
        }
        this.productCount = 0
        this.query = {
          'executed.equals': 'true',
          size: this.pageSize,
          page: this.page - 1,
          'productType.equals': 'GENERAL'
        }
        if (!this.JLTools.isNull(config.text)) {
          this.query['name.contains'] = config.text
        }
        if (!this.JLTools.isNull(this.params.firstCategoryId)) {
          this.query['firstCategoryId.equals'] = this.params.firstCategoryId
        }
        if (!this.JLTools.isNull(this.params.secondCategoryId)) {
          this.query['secondCategoryId.equals'] = this.params.secondCategoryId
        }
        if (!this.JLTools.isNull(this.params.thirdCategoryId)) {
          this.query['thirdCategoryId.equals'] = this.params.thirdCategoryId
        }
        if (!this.JLTools.isNull(this.sortItem)) {
          this.query.sort = this.sortItem
        }
        if (!this.JLTools.isNull(this.greaterThan)) {
          this.query['amount.greaterThan'] = this.greaterThan
        }
        if (!this.JLTools.isNull(this.lessThan)) {
          this.query['amount.lessThan'] = this.lessThan
        }
        // console.log(this.params)
        if (!this.JLTools.isNull(this.params.couponType)) {
          if (this.params.couponType === 'notIn') {
            this.query['id.notIn'] = this.params.productIds
          } else if (this.params.couponType === 'in') {
            this.query['id.in'] = this.params.productIds
          }
        }
        // console.log("查询参数",this.query);
        api.product
          .getProducts(this.query)
          .then(async (res) => {
            console.log('结果', res)
            //收藏商品
            this.collectProductList = await this.getCollectProduct()
            for (let item of res.data) {
              this.isCollect(item)
            }
            this.list = res.data
            this.productCount = res.headers['x-total-count']
            this.list.forEach((item) => {
              item.page = 0
            })
            this.$forceUpdate()
          })
          .finally(() => {
            this.loginFlag = false
            this.loading = false
            this.initLoad = true
          })
      },

      rounding(num) {
        return Math.floor(num)
      },
      isDecimal(num) {
        var y = String(num).indexOf('.') + 1
        return y > 0
      },
      //分页查询
      handCurrenPage(val) {
        // console.log(val)
        this.page = val
        this.getList({
          text: this.params.keyword || '',
          page: val,
          sx: 1
        })
      },
      //筛选
      sortlei(val) {
        this.sort = val
        this.sortItem = ''
        // if (val == 'Price') {
        //   this.priceRank = !this.priceRank
        //   if (this.priceRank) {
        //     this.sortItem = 'amount,asc'
        //   } else {
        //     this.sortItem = 'amount,desc'
        //   }
        // } 
        if(val == 'PriceLowToHigh') {
          this.sortItem = 'amount,asc'
        } else if(val == 'PriceHighToLow') {
          this.sortItem = 'amount,desc'
        } else if (val == 'Orders') {
          this.priceRank = false
          this.sortItem = 'saleCount,desc'
        } else if (val == 'Newest') {
          this.priceRank = false
          this.sortItem = 'createdDate,desc'
        } else {
          this.priceRank = false
        }
        this.loginFlag = true
        this.getList({
          text: this.params.text || '',
          page: 1,
          sx: 1
        })
      },
      //跳转商品详情
      toDetails(id) {
        const { href } = this.$router.resolve({
          name: 'productMain',
          params: {
            id: id
          }
        })
        window.open(href, '_blank')
      },
      getImgsList(imgs) {
        let imgList = []
        let num = 0
        ;(imgs || '').split(',').forEach((item) => {
          num += 1
          if (num > 3) {
            return false
          }
          let data = {
            img: item
          }
          imgList.push(data)
        })

        return imgList
      },
      //图片切换
      switchImg(data) {
        // console.log(data)
        data.product.coverImg = data.selImg.img
        data.product.page = data.index
      },
      addLabel(config) {
        if (this.JLTools.isNull(this.greaterThan) && this.JLTools.isNull(this.lessThan)) {
          this.close()
          return false
        }
        this.greaterLabel = ''
        this.lessLable = ''
        this.getList(config)
        this.priceLable = true
        if (!this.JLTools.isNull(this.greaterThan)) {
          this.greaterLabel = this.greaterThan
        }
        if (!this.JLTools.isNull(this.lessThan)) {
          this.lessLable = this.lessThan
        }
      },
      close() {
        this.priceLable = false
        this.greaterThan = ''
        this.lessThan = ''
        this.greaterLabel = ''
        this.lessLable = ''
        this.getList({ page: 1, text: this.params.text, sx: 1 })
      },
      clearAll() {
        this.close()
        this.filters.type = ''
        this.filters.price = ''
        this.filters.category = []
        this.filters.productFeatures = []
        this.filters.popularBrands = []
        this.filters.currentDeals = []
        this.colorList = [
          { name: 'Grey', code: '#B1B5B8', selected: false },
          { name: 'Red', code: '#A40000', selected: false },
          { name: 'Blue', code: 'var(--Zcolor)', selected: false },
          { name: 'Golden', code: '#BF8E34', selected: false }
        ]
      },
      guanzhu(product) {
        if (this.JLTools.isNull(userStore.userInfo.userId)) {
          return this.$router.push({
            path: '/login'
          })
        }
        if (this.collectLoadFlag) {
          return false
        }
        this.collectLoadFlag = true
        let data = {
          logicalDeleted: false,
          praised: false,
          product: product,
          userId: userStore.userInfo.userId
        }

        if (product.collectId) {
          data.id = product.collectId
          if (product.isCollect) {
            product.isCollect = false
            data.collected = false
          } else {
            product.isCollect = true
            data.collected = true
          }
          console.log(data, '取消收藏')
          api.product.productCollectPraises(data, 'put').then(async (res) => {
            this.collectLoadFlag = false
            this.collectProductList = await this.getCollectProduct()
            this.isCollect(product)
          })
        } else {
          data.collected = true
          product.isCollect = true
          api.product.productCollectPraises(data).then(async (res) => {
            this.collectLoadFlag = false
            this.collectProductList = await this.getCollectProduct()
            this.isCollect(product)
          })
        }
        // window.location.reload()
      },
      async getCollectProduct() {
        if (this.JLTools.isNull(userStore.userInfo.userId)) {
          return []
        }
        let params = {
          'collected.equals': true,
          'logicalDeleted.equals': false,
          'userId.equals': userStore.userInfo.userId,
          size: 1000
        }
        return await api.product
          .getGeneralProduct(params)
          .then((res) => {
            // console.log(res, '收藏数量')
            if (res && res.length) {
              userStore.setCollectProduct(res.length)
            }
            return res
          })
          .catch((error) => {
            return []
          })
      },
      /**
       * 判断是不是关注商品
       * @param id
       */
      isCollect(product) {
        if (this.JLTools.isNull(userStore.userInfo.userId)) return
        product.isCollect = false
        if (!this.JLTools.isNull(this.collectProductList)) {
          this.collectProductList.forEach((res) => {
            if (res.product.id === product.id && res.collected) {
              product.isCollect = true
              product.collectId = res.id
            } else if (res.product.id === product.id && !res.collected) {
              product.isCollect = false
              product.collectId = res.id
            }
          })
        }
      },
      changeNum(value) {
        if (value === 'greaterThan') {
          if (this.greaterThan.length === 1) {
            if (this.greaterThan === 0 || this.greaterThan === '0') {
              this.greaterThan = ''
            } else {
              this.greaterThan = this.greaterThan.replace(/[^0-9]/g, '')
            }
          } else {
            this.greaterThan = this.greaterThan.replace(/[^\d]/g, '')
          }
        } else if (this.lessThan.length === 1) {
          if (this.lessThan === 0 || this.lessThan === '0') {
            this.lessThan = ''
          } else {
            this.lessThan = this.lessThan.replace(/[^0-9]/g, '')
          }
        } else {
          this.lessThan = this.lessThan.replace(/[^\d]/g, '')
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .content {
    width: 100%;
    height: 100%;
    min-height: 500px;

    .sy {
      // display: flex;
      // justify-content: center;
      width: var(--minWidth);
      margin: 0 auto;
    }
  }

  .pro_list_search {
    float: left;
    width: 200px;
    max-height: 600px;
    position: relative;
    background: #fff;
    font-size: 13px;
    margin: 15px 0 0 0;
    padding: 0 20px;
    display: none;
  }

  .pro_list_search .none {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
    z-index: 1;
    cursor: no-drop !important;
  }

  .pro_list_search .pro_search_item {
    float: left;
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid #e4e4e4;
  }

  .pro_list_search .pro_search_item:last-child {
    border-bottom: 0px;
  }

  .pro_search_header {
    float: left;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
  }

  .pro_search_body {
    float: left;
    width: 100%;
  }

  .pro_search_body .brands_item {
    float: left;
    width: 85px;
    height: 36px;
    border: 1px solid #eee;
    margin: 0 0 10px 10px;
    overflow: hidden;
  }

  .pro_search_body .brands_item:nth-child(2n + 1) {
    margin-left: 0px;
  }

  .pro_search_body .brands_item.xuan {
    border: 1px solid var(--btnBgColor);
  }

  .pro_search_body .brands_item img {
    float: left;
    width: 100%;
    height: 100%;
  }

  .pro_search_body .checkbox_item {
    float: left;
    width: 100%;
  }

  .pro_search_body .checkbox_item .checkbox {
    margin: 8px 5px 8px 0;
  }

  .pro_search_body .checkbox_item span {
    float: left;
    line-height: 30px;
  }

  .pro_search_body .checkbox_item.xuan span {
    color: var(--btnBgColor);
  }

  .pro_search_body .checkbox_item.xuan .checkbox {
    background: var(--btnBgColor);
    border: 1px solid var(--btnBgColor);
  }

  .pro_search_body .checkbox_item.xuan .checkbox img {
    display: block;
  }

  .shaixuan_line {
    float: left;
    border-left: 1px solid #ccc;
    height: 20px;
    margin: 7px 30px;
  }

  .shaixuan_card_price {
    float: left;
    margin-bottom: 16px;
  }

  .shaixuan_card_price input:first-child {
    margin-left: 0px;
  }

  .shaixuan_card_price input {
    float: left;
    // height: 34px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    width: 112px;
    margin-left: 12px;
    padding: 5px 12px;
  }

  .shaixuan_card_price input::placeholder {
    color: #A8ABB2;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .shaixuan_card_price span {
    float: left;
    line-height: 34px;
    margin-left: 10px;
  }

  .shaixuan_card_price button {
    float: left;
    height: 30px;
    width: 39px;
    background: var(--mainBgColor);
    color: var(--mainTextColor);
    margin-left: 14px;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .pro_search_body a {
    float: left;
    width: 100%;
    line-height: 25px;
  }

  .pro_search_body a:hover {
    color: var(--btnBgColor);
    text-decoration: underline;
  }

  .pro_search_body .pro_pingjia_star {
    margin-top: 2.5px;
  }

  .pro_list {
    float: left;
    margin-top: 40px;
    // width: 100%;
  }

  .pro_list_body {
    float: left;
    width: 895px;
    position: relative;
    .left-section {
      width: 510px;
      display: flex;
      justify-content: space-between;
      .breadcrumbs {
        color: #909399;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        gap: 8px;
        display: flex;
        .prev {
          color: #303133;
          font-weight: 500;
        }
        .current {
          color: #606266;
        }
      }
    }
    .resultsFound {
      margin-bottom: 16px;
      color: #606266;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      span {
        color: var(--mainBgColor);
        font-weight: 700;
      }
    }
    .activeFilters {
      margin-bottom: 16px;
      border-radius: 4px;
      background-color: #FBFBFB;
      padding: 12px 16px 0px 16px;
      color: #606266;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .sortBy {
      color: #303133;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      width: 373px;
      margin-left: 12px;
      span {
        margin-right: 22px;
        width: 51px;
      }
      .option-box {
        width: 300px;
      }
    }
    .list-box {
      display: grid;
      grid-template-columns: repeat(4, 1fr); /* 将容器分成6列，每列宽度相等 */
      gap: 32px 16px;
      justify-content: space-between;
    }
  }

  .pro_list_loading {
    float: left;
    color: #666;
    width: calc(100% - 20px);
    position: absolute;
    z-index: 1;
    margin: 0 0 10px 10px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff5e1;
  }

  .pro_list_body .pro_list_item {
    border-radius: 8px;
    overflow: hidden;
    width: 212px;
    min-height: 300px;
    background: #fff;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .pro_list_body .pro_list_item .pro_img {
    position: relative;
    width: 100%;
    .pro_zimg {
      width: 100%;
    }
  }

  .pro_list_body .pro_list_item .pro_img {
    .guanzhu {
      position: absolute;
      opacity: 0; /* 初始状态透明 */
      width: 32px;
      height: 32px;
      right: 10px;
      bottom: 10px;
      background: #fff;
      box-shadow: 0 0 8px #999;
      padding: 7px;
      border-radius: 50%;
      z-index: 1;
      cursor: pointer;
    }
    &:hover {
      .guanzhu {
        opacity: 1; /* 鼠标悬停时透明度变为 1 */
      }
    }
    .sold-out {
      position: absolute;
      right: 0;
      top: 0;
      color: #fff;
    }
  }

  .pro_list_body .pro_list_item .pro_info {
    width: 100%;
    padding: 16px 12px;
  }

  .pro_list_body .pro_list_item .pro_info .pro_sx {
    float: left;
    width: 100%;
  }

  .pro_list_body .pro_list_item .pro_info .pro_sx .pro_sx_item {
    float: left;
    border-radius: 3px;
    width: 32px;
    height: 32px;
    padding: 2px;
    border: 1px solid #ccc;
    margin: 5px 0 5px 5px;
  }

  .pro_list_body .pro_list_item .pro_info .pro_sx .pro_sx_item.xuan {
    border: 1px solid var(--btnBgColor) !important;
  }

  .pro_list_body .pro_list_item .pro_info .pro_sx .pro_sx_item:first-child {
    margin-left: 0px;
  }

  .pro_list_body .pro_list_item .pro_info .pro_sx .pro_sx_item:hover {
    border: 1px solid var(--btnBgColor);
  }

  .pro_list_body .pro_list_item .pro_info .pro_name {
    // width: 100%;
    // height: 40px;
    line-height: 20px;
    font-size: 16px;
    font-weight: 400;
    color: var(--mainBgColor);
  }

  .pro_list_body .pro_list_item .pro_info .pro_name:hover {
    cursor: pointer;
  }

  .pro_list_body .pro_list_item .pro_info .pro_pic_Y {
    background-color: #eef6fb;
    border: 1px solid #cae3f4;
    padding: 6px 12px;
    margin-bottom: 8px;
    width: 80px;
    height: 28px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #53a4db;
    border-radius: 4px;
  }

  .pro_list_body .pro_list_item .pro_info .pro_maidian {
    float: left;
    width: 100%;
    color: #222;
    margin-top: 8px;
    user-select: text;
    display: flex;
    align-items: center;
    :deep(.wujiaoxing) {
      margin-right: 0px;
    }
    .score-num {
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      color: #606266;
    }
  }

  .pro_list_body .pro_list_item .pro_info .pro_pic {
    // float: left;
    // width: 100%;
    // height: 20px;
    line-height: 28px;
    margin-top: 8px;
    font-size: 18px;
    font-weight: 400;
    color: var(--Zcolor);
  }

  .pro_list_body .pro_list_item .pro_info .pro_pic_x {
    // float: left;
    // width: 100%;
    // height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #909399;
    text-decoration: line-through;
  }

  .pro_list_body .pro_list_item .pro_info .pro_pic_w {
    float: left;
    width: 100%;
    height: 20px;
  }

  .pro_list_body .pro_list_item .pro_info .pro_yunfei {
    float: left;
    width: 100%;
    height: 20px;
    margin-top: 5px;
    font-size: 11px;
    color: blue;
  }

  .pro_list_body .pro_list_item .pro_info .pro_yunfei span {
    line-height: 12px;
    border-left: 1px solid #eee;
    padding: 0 8px;
  }

  .pro_list_body .pro_list_item .pro_info .pro_yunfei span:first-child {
    border: 0px;
    padding-left: 0px;
  }

  .pro_info {
    .xiaoliang {
      width: 100%;
      font-size: 11px;
      line-height: 25px;
      color: #999;

      .pro_pingjia {
        margin-left: 5px;
      }
    }
  }

  .pro_pingjia_star {
    float: left;
    height: 25px;
  }

  .pro_pingjia_star img {
    float: left;
    width: 15px;
    height: 15px;
    margin: 5px 2px 0 0;
  }

  .pro_biaoqian {
    color: #fff;
    line-height: 20px;
    border-radius: 3px;
    font-size: 10px;
    padding: 0 5px;
  }

  .pro_biaoqian img {
    width: 12px;
    height: 12px;
    float: left;
    margin: 4px 2px 4px 0;
  }

  .new_pro {
    background: var(--btnBgColor);
  }

  .hot_pro {
    background: #fe6e2f;
  }

  .pro_list_body .pro_list_item:hover {
    box-shadow: 0px 0px 12px 0px rgba(83, 164, 219, 0.12);
    transition: box-shadow 0.5s;
  }

  .pro_list_shaixuan {
    float: left;
    width: 289px;
    margin-right: 16px;
    .lastItem {
      border-bottom: none !important;
    }
  }

  .pro_list_shaixuan .shaixuan_daohang {
    float: left;
    width: 100%;
    font-size: 14px;
    line-height: 30px;
  }

  .pro_list_shaixuan .shaixuan_daohang span {
    float: left;
  }

  .pro_list_shaixuan .shaixuan_daohang img {
    float: left;
    margin: 9px 5px;
    width: 12px;
    height: 12px;
    transform: rotate(270deg);
  }

  .pro_list_shaixuan .shaixuan_daohang b {
    float: left;
  }

  .pro_list_shaixuan .shaixuan_card {
    float: left;
    display: grid;
    border-bottom: 1px solid #DCDFE6;
    margin-bottom: 12px;
    padding-bottom: 12px;
    width: 100%;
    .el-radio-group {
      display: grid;
      gap: 4px;
      :deep(.el-radio__input.is-checked .el-radio__inner) {
        background-color: var(--Zcolor);
        border-color: var(--Zcolor);
      }
      :deep(.el-radio__inner:hover) {
        border-color: var(--Zcolor);
      }
      :deep(.el-radio__input.is-checked+.el-radio__label) {
        color: #303133;
      }
      :deep(.el-radio__label) {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .el-checkbox-group {
      display: grid;
      gap: 4px;
      :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
        background-color: var(--Zcolor);
        border-color: var(--Zcolor);
      }
      :deep(.el-checkbox__inner:hover) {
        border-color: var(--Zcolor);
      }
      :deep(.el-checkbox__input.is-checked+.el-checkbox__label) {
        color: #303133;
      }
      :deep(.el-checkbox__label) {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .colors {
      .singleColor {
        height: 44px;
        width: 44px;
        border-radius: 160px;
        padding: 6px;
        display: table;
        margin-right: 12px;
        cursor: pointer;
      }
      .selected {
        border: 2px solid var(--Zcolor);
      }
      span {
        height: 32px;
        width: 32px;
        border-radius: 160px;
        display: table;
      }
      .singleColor:hover {
        border: 2px solid var(--Zcolor);
      }
    }
  }

  .pro_list_shaixuan .shaixuan_card .sort_bya_title {
    float: left;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #303133;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  .pro_list_shaixuan .shaixuan_card .sort_bya {
    float: left;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    margin-left: 10px;
  }

  .pro_list_shaixuan .shaixuan_card .sort_bya .sort_bya_item {
    float: left;
    font-size: 14px;
    padding: 0 15px;
    margin: 8px 0;
    height: 16px;
    line-height: 16px;
    border-left: 1px solid #e4e4e4;
  }

  .pro_list_shaixuan .shaixuan_card .sort_bya .sort_bya_item:first-child {
    border-left: 0px;
  }

  .pro_list_shaixuan .shaixuan_card .sort_bya .sort_bya_item:hover {
    cursor: pointer;
    color: var(--btnBgColor);
  }

  .pro_list_shaixuan .shaixuan_card .sort_bya .sort_bya_item.xuan {
    font-weight: bold;
    color: var(--btnBgColor);
  }

  .pro_list_shaixuan .shaixuan_card .sort_bya .sort_bya_item .icon {
    float: right;
    width: 10px;
    height: 20px;
    margin: -2px 0 0 6px;
  }

  .pro_list_shaixuan .shaixuan_card .sort_bya .sort_bya_item .icon img {
    float: left;
    width: 10px;
    height: 10px;
  }

  .pro_list_shaixuan .shaixuan_card .sort_bya .sort_bya_item .icon img.down {
    transform: rotate(180deg);
  }

  .filtered_item {
    // float: left;
    // border: 1px solid #CAE3F4;
    // border-radius: 4px;
    // padding: 6px 12px;
    // background: #EEF6FB;
    // line-height: 22px;
    flex-wrap: wrap;
    :deep(.el-tag .el-tag__close) {
      color: #53A4DB;
    }
    :deep(.el-tag.el-tag--primary) {
    border: 1px solid #CAE3F4;
    border: 1px solid #CAE3F4;
    border-radius: 4px;
      border: 1px solid #CAE3F4;
    border-radius: 4px;
      padding: 6px 12px;
      background: #EEF6FB;
      margin: 0 16px 12px 0;
    }
    :deep(.el-tag .el-tag__close:hover) {
      color: var(--mainTextColor);
      background-color: var(--Zcolor);
    }
  }

  .filtered_item span {
    float: left;
    color: #53A4DB;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
  }

  .filtered_item i {
    float: left;
    // font-size: 18px;
    // line-height: 18px;
    margin-left: 8px;
    color: #53A4DB;
    height: 14px;
    width: 14px;
    justify-content: center;
  }

  .filtered_item:hover {
    cursor: pointer;
  }

  // .filtered_item:hover i {
  //   color: #000;
  // }

  .clearAll {
    margin-bottom: 12px;
  }

  .shaixuan_card a {
    float: left;
    line-height: 34px;
    margin-left: 10px;
    color: var(--btnBgColor);
    font-size: 14px;
  }

  .shaixuan_view_item {
    float: left;
    width: 24px;
    height: 24px;
    margin: 5px 0 5px 10px;
  }

  .shaixuan_view_item:hover {
    cursor: pointer;
  }

  .pro_list_shaixuan .shaixuan_checkbox {
    float: left;
  }

  .pro_list_shaixuan .shaixuan_checkbox .checkbox {
    margin: 9px 8px 9px 0;
  }

  .pro_list_shaixuan .shaixuan_checkbox span {
    float: left;
    line-height: 34px;
    font-size: 14px;
  }

  .pro_list_shaixuan .shaixuan_checkbox.xuan span {
    color: var(--btnBgColor);
    font-weight: bold;
  }

  .pro_list_shaixuan .shaixuan_checkbox.xuan .checkbox {
    background: var(--btnBgColor);
    border: 1px solid var(--btnBgColor);
  }

  .pro_list_shaixuan .shaixuan_checkbox.xuan .checkbox img {
    display: block;
  }

  .pro_list_shaixuan .pro_biaoqian {
    margin: 7px 0;
  }

  .fenye {
    float: left;
    width: 100%;
    height: 60px;
    background: #fff;
    margin: 40px 0;
    border-radius: 4px;
  }

  .fenye_left {
    float: left;
    line-height: 60px;
    margin-left: 10px;
    font-size: 13px;
    color: #666;
  }

  .fenye_left b {
    font-size: 14px;
    color: #000;
  }

  .fenye_right {
    // float: right;
    // padding: 7px 0;
    // margin-right: 5px;
    display: flex;
    justify-content: center;
  }

  .checkbox {
    float: left;
    width: 14px;
    height: 14px;
    border: 1px solid #aaa;
    cursor: pointer;
  }

  .checkbox img {
    float: left;
    width: 100% !important;
    height: 100% !important;
    display: none;
    margin: 0px !important;
  }

  .checkbox.xuan {
    background: var(--btnBgColor);
    border: 1px solid var(--btnBgColor);
  }

  .checkbox.xuan img {
    display: block;
  }

  .content_none {
    width: var(--minWidth);
    margin: 0 auto;
  }

  .pro_list_none {
    width: var(--minWidth);
    background: #fff;
    margin-top: 20px;
    padding: 40px;
  }

  .pro_list_none img {
    width: 100px;
    height: 100px;
  }

  .pro_list_none .txt {
    padding-left: 15px;
  }

  .pro_list_none .txt p {
    font-size: 16px;
  }

  .pro_list_none .txt .btn {
    float: left;
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
  }

  .pro_list_none .txt .btn span {
    float: left;
    color: #999;
  }

  .pro_list_none .txt .btn a {
    float: left;
    margin-left: 15px;
    color: var(--btnBgColor);
  }

  .pro_list_none .txt .btn a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
</style>
